// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-awards-js": () => import("/opt/build/repo/src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-education-js": () => import("/opt/build/repo/src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-employment-js": () => import("/opt/build/repo/src/pages/employment.js" /* webpackChunkName: "component---src-pages-employment-js" */),
  "component---src-pages-home-js": () => import("/opt/build/repo/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-js": () => import("/opt/build/repo/src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-resume-js": () => import("/opt/build/repo/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-skills-js": () => import("/opt/build/repo/src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */)
}

